<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :to="{ name: 'game-configuration'}"
          >
            <span>{{ $t('BUTTON.ADD_GAME') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
          <b-badge
            v-if="archiveFilter"
            pill
            variant="light-info"
            class="text-capitalize ml-2"
          >
            {{ $t('ARCHIVE') }}
          </b-badge>
          <div
            v-if="isDataLoad"
            class="ml-5"
          >
            <b-button
              variant="outline-primary"
              disabled
            >
              <b-spinner
                small
                type="grow"
              />
              {{ $t('LOADING') }}...
            </b-button>

          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          :md="userData.userRoleId===userRole.ADMIN ? 3 : 6"
        >
          <div class="text-nowrap">
            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
            >
              <template #button-content>
                <feather-icon
                  size="17"
                  icon="MoreVerticalIcon"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item
                v-model="archiveFilter"
                @click="onArchive(true)"
              >
                {{ $t('ARCHIVE') }}
              </b-dropdown-item>
              <b-dropdown-item
                v-model="archiveFilter"
                @click="onArchive(false)"
              >
                {{ $t('NOT_ARCHIVE') }}
              </b-dropdown-item>
            </b-dropdown>
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('SEARCH')"
            />
          </div>
        </b-col>
        <b-col
          v-show="userData.userRoleId===userRole.ADMIN"
          cols="12"
          md="3"
        >
          <v-select
            id="id-company"
            v-model="companyFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('SELECT_COMPANY')"
            label="companyname"
            :options="companies"
          />
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refGameListTable"
      :items="fetchGamesAll"
      responsive
      :fields="gameTableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: Name of the game -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :text="avatarText(data.item.c_string_swe.substring(0,1))"
              :variant="`light-${resolveGameStatusVariant(data.item).variant}`"
              :to="{ name: 'game-view', params: { id: data.item.encrypt_id } }"
            />
          </template>
          <b-link
            :to="{ name: 'game-view', params: { id: data.item.encrypt_id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ strSubstring(data.item.c_string_swe,20) }}
          </b-link>
          <small class="text-muted"> <b-badge
            pill
            :variant="`light-${resolveGameStateVariant(data.item.gamestate).variant}`"
            class="text-capitalize small"
          >
            {{ resolveGameStateVariant(data.item.gamestate).name }}
          </b-badge>
          </small>
        </b-media>
      </template>

      <!-- Column:Category -->
      <template #cell(Category)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            class="mr-1"
            :variant="data.item.templates_category !== null ? data.item.templates_category.avatar_color : 'light-primary'"
          >
            <feather-icon
              size="18"
              :icon="data.item.templates_category !== null ? data.item.templates_category.avatar_icon : 'AwardIcon'"
            />
          </b-avatar>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.templates_category !== null ? data.item.templates_category.name : 'Other' }}
          </span>
        </div>
      </template>

      <!-- Column:StartDate -->
      <template #cell(StartDate)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.startdate) }}
          </span>
        </b-media>
      </template>
      <!-- Column:EndDate -->
      <template #cell(EndDate)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.enddate) }}
          </span>
        </b-media>
      </template>
      <!-- Column: Status -->
      <template #cell(Status)="data">
        <b-badge
          pill
          :variant="`light-${resolveGameStatusVariant(data.item).variant}`"
          class="text-capitalize small"
        >
          {{ resolveGameStatusVariant(data.item).text }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            v-show="canEditDeleteGame(data.item)"
            :id="data.item.ID"
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'game-edit-id', params: { id: data.item.ID }})"
          />
          <feather-icon
            :id="`game-view-${data.item.encrypt_id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'game-view', params: { id: data.item.encrypt_id }})"
          />
          <feather-icon
            v-show="canDeleteGame(data.item)"
            :id="`game-delete-${data.item.encrypt_id}-preview-icon`"
            icon="Trash2Icon"
            size="16"
            class="mr-1 cursor-pointer"
            @click="deleteCompetition(data.item)"
          />
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="$router.push({ name: 'activity-list-id', params: {id: data.item.encrypt_id }})">
              <feather-icon icon="ActivityIcon" />
              <span class="align-middle ml-50">{{ $t('HABIT') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canEditDeleteGame(data.item)"
              @click="showMessageEmailInvitation(data.item)"
            >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">{{ $t('INVITATION_EMAIL') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="$router.push({
                name: 'game-template',
                params: { id: data.item.encrypt_id },
                query: { object: 'game' }
              })"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ $t('CREATE_TEMPLATE') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="$router.push({ name: 'game-configuration-id', params: {id: data.item.encrypt_id }})">
              <feather-icon
                icon="CopyIcon"
              />
              <span class="align-middle ml-50">{{ $t('DUPLICATE') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="chanegeCompetitionState(data.item)">
              <feather-icon
                :icon="resolveGameStateVariant(data.item.gamestate).icon"
              />
              <span class="align-middle ml-50">{{ $t(resolveGameStateVariant(data.item.gamestate).i18n) }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="refreshCompetition(data.item)">
              <feather-icon
                icon="RefreshCcwIcon"
              />
              <span class="align-middle ml-50">{{ $t('REFRESH') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="analyzeGame(data.item)"
            >
              <feather-icon icon="SlackIcon" />
              <span class="align-middle ml-50">{{ $t('AI_ANALYZE') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="submitArchive(data.item)">
              <feather-icon icon="ArchiveIcon" />
              <span
                v-if="data.item.isArchive===0"
                class="align-middle ml-50"
              >{{ $t('ARCHIVE') }}</span>
              <span
                v-if="data.item.isArchive===-1"
                class="align-middle ml-50"
              >{{ $t('UNARCHIVE') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalGames"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BLink,
  BRow, BCol, BFormInput, BButton, BTable,
  BMedia, BDropdown, BDropdownItem,
  BPagination, BBadge, BAvatar,
  VBPopover, BSpinner,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import store from '@/store'
import useGameList from './useGameList'
import constants from '@/constants/static.json'
import gameStore from '@/store/game/gameStore'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
// eslint-disable-next-line import/named
import { canEditDeleteGame, canDeleteGame, resolveGameStateVariant } from '@/constants/utils'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBadge,
    BAvatar,
    BLink,
    vSelect,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      userData: useJwt.getUser(),
      isArchive: false,
      userRole: constants.USER_ROLE,
    }
  },
  setup() {
    const templateGame = ref({})
    const isTemplateHandlerSidebarActive = ref(false)
    const openSidebar = data => {
      templateGame.value = data
      isTemplateHandlerSidebarActive.value = true
    }

    const clearTemplateData = () => {
      templateGame.value = JSON.parse(JSON.stringify({}))
    }

    const { APP_STORE_MODULE } = constants

    // Register module
    if (!store.hasModule(APP_STORE_MODULE)) store.registerModule(APP_STORE_MODULE, gameStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE)) store.unregisterModule(APP_STORE_MODULE)
    })

    const {
      fetchGamesAll,
      fetchArchiveGames,
      gameTableColumns,
      companies,
      perPage,
      currentPage,
      totalGames,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      isDataLoad,
      refGameListTable,
      companyFilter,
      archiveFilter,
      refetchData,
      resolveGameStatusVariant,
      templateCategories,
      refetchList,
    } = useGameList()

    const {
      successMessage,
      warningMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      fetchGamesAll,
      fetchArchiveGames,
      gameTableColumns,
      perPage,
      currentPage,
      totalGames,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      isDataLoad,
      refGameListTable,
      companyFilter,
      archiveFilter,
      refetchData,
      avatarText,
      resolveGameStatusVariant,
      resolveGameStateVariant,
      templateGame,
      isTemplateHandlerSidebarActive,
      openSidebar,
      companies,
      clearTemplateData,
      templateCategories,
      canEditDeleteGame,
      canDeleteGame,
      successMessage,
      warningMessage,
      showErrorMessage,
      refetchList,
    }
  },
  methods: {
    ...mapActions('game', [
      'createGameAnalyze',
      'deleteGame',
      'updateGame',
      'sendInvitationEmail']),
    deleteCompetition(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.c_string_swe}  ${this.$i18n.t('GAME')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteGame(data.ID).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.GAME_DELETED'))
                this.$router.go() // This will reload the current route
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    analyzeGame(data) {
      let message
      if (data.dev_only) {
        message = `${this.$i18n.t('MESSAGE.RE_ANALYZE_CONFIRMATION')} ?`
      } else {
        message = `${this.$i18n.t('MESSAGE.ANALYZE_CONFIRMATION')} ${data.c_string_swe}   ${this.$i18n.t('GAME')} ?`
      }
      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.warningMessage(this.$i18n.t('MESSAGE.ANALYZE_WAIT'))
            this.onAnalyzeGame(data)
          }
        })
    },
    refreshCompetition(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.REFRESH_CONFIRMATION')} ${data.c_string_swe}   ${this.$i18n.t('GAME')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            // eslint-disable-next-line no-param-reassign
            data.masterlevel += 1
            this.updateGame(data).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.GAME_REFRESHED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    chanegeCompetitionState(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.STATE_CONFIRMATION')} ${data.c_string_swe}   ${this.$i18n.t('GAME')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            // eslint-disable-next-line no-param-reassign
            data.gamestate = this.resolveGameStateVariant(data.gamestate).id
            // eslint-disable-next-line no-param-reassign
            data.masterlevel += 1
            this.updateGame(data).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.GAME_UPDATED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    onArchive(value) {
      this.archiveFilter = value
    },
    showMessageEmailInvitation(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.INVITATION_GROUP_EMAIL_CONFIRMATION')} ${data.c_string_swe}   ${this.$i18n.t('GAME')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.sendInvitationEmail({ competition: data }).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.INVITATION_EMAIL_SEND'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    onAnalyzeGame(data) {
      if (data) {
        this.createGameAnalyze(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.ANALYZE_CREATED'))
            // eslint-disable-next-line no-param-reassign
            data.dev_only = 1
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
    submitArchive(data) {
      const payload = { ...data }
      let message = `${this.$i18n.t('MESSAGE.ARCHIVE_CONFIRMATION')} ${data.c_string_swe}  ${this.$i18n.t('GAME')} ?`
      if (data.isArchive === -1) {
        message = `${this.$i18n.t('MESSAGE.UNARCHIVE_CONFIRMATION')} ${data.c_string_swe}  ${this.$i18n.t('GAME')} ?`
        // eslint-disable-next-line no-unused-expressions, no-param-reassign
        payload.isArchive = 0
      } else {
        // eslint-disable-next-line no-param-reassign
        payload.isArchive = -1
      }
      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('app-game/updateCompetition', payload)
              .then(() => {
                this.$router.go(this.$router.currentRoute)
                this.successMessage(this.$i18n.t('MESSAGE.GAME_UPDATED'))
              })
          }
        })
    },
    diffDate(firstDate, secondDate) {
      const dateOut1 = new Date(firstDate)
      const dateOut2 = new Date(secondDate)
      const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(Math.abs((dateOut1.getTime() - dateOut2.getTime()) / (oneDay)))
      return diffDays
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
